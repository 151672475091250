import { CacheSelector, cache } from '../cache';
import { each } from '../lib/localStorage';
import { logger } from '../logger';
import { send } from '../sensorsdata';

interface IUserProfile {
  auth_time?: number;
  country?: string;
  email?: string;
  email_verified?: boolean;
  flow?: string;
  jit?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  sub?: string;
}

// key prefix
const OIDC_USER_KEY_PREFIX = 'oidc.user';

/**
 * get user profile form localStorage
 * @returns userProfile {IUserProfile}
 */
const getUserProfile = (): IUserProfile => {
  let userProfile: IUserProfile = {};
  each((value: Record<string, unknown>, key: string): void => {
    // @ts-ignore
    if (key.startsWith(OIDC_USER_KEY_PREFIX)) {
      userProfile = value.profile as IUserProfile;
    }
  });

  return userProfile;
};

/**
 * sensors user login
 * @link: https://manual.sensorsdata.cn/sa/latest/web-js-sdk-7548149.html#id-.WebJSSDKv1.13-用户登录
 * @param upmId {string}
 */
const sensorsLogin = async (upmId: string): Promise<void> => {
  await send('login', upmId);

  return Promise.resolve();
};

/**
 * init user profile form localStorage
 * @returns
 */
const initUserProfile = async (): Promise<void> => {
  try {
    const { sub: upmId } = getUserProfile();
    if (upmId) {
      // save to cache
      cache.set(CacheSelector.UPM_ID, upmId);

      // login to sensors
      await sensorsLogin(upmId);
    }

    return Promise.resolve();
  } catch (err) {
    logger.error(`failed to init user profile: ${JSON.stringify(err)}`);

    return Promise.resolve();
  }
};

export { getUserProfile, initUserProfile };
