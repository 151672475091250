import { CacheSelector, cache } from '../cache';
import { getUserProfile } from '../initialize/init-user-profile';
import { send } from '../sensorsdata';

/**
 * get the user upmId first, if not, check localStorage and cache
 * @returns upmId {string}
 */
const getUpmId = (): string | undefined => {
  const upmId = cache.get(CacheSelector.UPM_ID) as string;
  const { sub: newUpmId } = getUserProfile();

  // login or switch user login
  if ((!upmId && newUpmId) || (upmId && newUpmId && upmId !== newUpmId)) {
    // save to cache
    cache.set(CacheSelector.UPM_ID, newUpmId);
    // login to sensors
    send('login', newUpmId);

    return newUpmId;

    // logout
  } else if (upmId && !newUpmId) {
    cache.set(CacheSelector.UPM_ID, undefined);

    return undefined;
  }

  return upmId;
};

export { getUpmId };
