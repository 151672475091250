enum CacheSelector {
  ADOBE_ANALYTICS_ID,
  ADOBE_MARKETING_CLOUD_ID,
  ANONYMOUS_ID,
  DEBUG_MODE_ENABLED,
  DRY_RUN_MODE_ENABLED,
  GLOBAL_BROWSER_LANGUAGE,
  GLOBAL_DOMAIN,
  GLOBAL_PATH,
  GLOBAL_REFERRER,
  GLOBAL_SEARCH,
  GLOBAL_TITLE,
  GLOBAL_URL,
  GLOBAL_USER_AGENT,
  LIBRARY_NAME,
  LIBRARY_VERSION,
  LOADED,
  COUNTRY_CODE,
  TIMEOUT,
  UPM_ID,
  USER_TYPE,
  PREVIOUS_VIEW_PAGE_NAME,
  PREVIOUS_VIEW_PAGE_TYPE,
  PRIVACY_ALLOW_MARKETING,
  PRIVACY_ALLOW_PERFORMANCE,
  VIEW_PAGE_NAME,
  VIEW_PAGE_TYPE,
  WRITE_KEY,
  MBOX,
  SIGN_IN_FLOW
}

export { CacheSelector };
