// see https://www.typescriptlang.org/docs/handbook/modules.html
// eslint-disable-next-line @typescript-eslint/no-require-imports
import words = require('lodash/words');
import { isObject, mapKeys } from 'lodash';
import { AnalyticsEvent } from '../analytics-event';
import { replaceString } from '../lib/string';

const DataUtils = {

  /**
   * 原 SDK 事件对象转换为 Sensors 事件对象
   * @param analyticsEvent {AnalyticsEvent}
   * @returns SensorsData JSONObject
   */
  analyticsEventToSensorsEvent(
    analyticsEvent: AnalyticsEvent
  ): { [props: string]: string } {
    const sensorsEventProperties: { [props: string]: string } = {};

    // 处理 context 属性 => sensorsData.properties
    if (isObject(analyticsEvent.context)) {
      sensorsEventProperties.context = this.objectToJsonStr(
        analyticsEvent.context
      );
    } else {
      sensorsEventProperties.context = analyticsEvent.context;
    }

    // 处理 options 属性 => sensorsData.properties
    if (isObject(analyticsEvent.options)) {
      sensorsEventProperties.options = this.objectToJsonStr(
        analyticsEvent.options
      );
    }

    // 处理 properties 属性 => sensorsData.properties
    mapKeys(analyticsEvent.properties, (value, key): void => {
      const newKey = replaceString(key);
      if (isObject(value)) {
        sensorsEventProperties[newKey] = this.objectToJsonStr(value);
      } else if (value) {
        sensorsEventProperties[newKey] = value;
      }
    });

    return sensorsEventProperties;
  },

  /**
   * 对象转换为 JSON 字符串
   * @param obj {object}
   * @returns string
   */
  objectToJsonStr(obj: unknown): string {
    if (obj && isObject(obj)) {
      return JSON.stringify(obj);
    }

    return '';
  },

  /**
   * 处理字符串，转为 snake_case 形式，但是不自动转换 word 大小写
   * @param str {string} 待处理字符
   * @param separator {string} 分割符, default '_'
   * @example "Foo Bar" => "Foo_Bar"
   * @like lodash/snakeCase https://github.com/lodash/lodash/blob/master/snakeCase.js
   */
  snakeCaseByOrigin(str: string, separator?: string): string {
    const sep = separator || '_';

    return words(str.replace(/['\u2019]/g, '')).reduce(
      (result, word, index): string => result + (index ? sep : '') + word,
      ''
    );
  }
};

export { DataUtils };
