class PrivacyError extends Error {
  public constructor(message: string) {
    super(message);
    this.name = 'PrivacyError';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (<any>Object).setPrototypeOf(this, new.target.prototype);
  }
}

export { PrivacyError };
