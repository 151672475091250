import { Md5 } from './lib/md5';

/**
 * 获取消息 id，用于标识消息的唯一性。
 * @returns {string}
 */
const generateMessageId = (): string => {
  const randomString = Math.random().toString();
  const hashId = Md5.hashStr(new Date().toISOString() + randomString);

  return `ajs-${hashId}`;
};

export { generateMessageId };
