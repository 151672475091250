import {
  AnalyticsCallback,
  AnalyticsSensorsOptions,
  extractLoadArgs,
  extractPageArgs
} from './common';
import { CacheSelector, cache, isDryRunMode } from '../cache';
import { RawEvent, makeAnalyticsEvent } from '../analytics-event';
import { initialize, ready } from '../initialize';
import { DataUtils } from '../sensorsdata/data-utils';
import { generateMessageId } from '../message-id';
import { has } from 'lodash';
import { logger } from '../logger';
import { metrics } from '../metrics';
import { replaceString } from '../lib/string';
import { send } from '../sensorsdata';
import { setDebugMode } from '../debug';

/**
 * dry run mode callback
 * @param payload
 * @param cb
 * @returns
 */
const handleCallback = (payload: object, cb?: AnalyticsCallback): void => {
  // get timeout form global cache
  const timeout = cache.get(CacheSelector.TIMEOUT) as number;
  if (!cb) {
    return;
  }
  logger.warn(
    'DRYRUN WARNING: callback payload does not contain the sensorsdata preset property, you can check this out by calling the getPresetProperties'
  );

  // 延时回调, default 300ms
  setTimeout((): void => {
    cb(payload);
  }, timeout);
};

/**
 * debug model
 * @param namespace
 */
const debug = (namespace?: string | false): void => {
  if (typeof namespace === 'boolean' && Boolean(namespace)) {
    setDebugMode('', '');
  } else {
    setDebugMode(namespace);
  }
};

const load = (
  writeKeyOrOptions?: unknown,
  analyticsOptionsOrSensorsOptions?: unknown,
  justSensorsOptions?: AnalyticsSensorsOptions | undefined
): void => {
  try {
    const { writeKey, analyticsOptions, sensorsOptions } = extractLoadArgs(
      writeKeyOrOptions,
      analyticsOptionsOrSensorsOptions,
      justSensorsOptions
    );

    if (!has(sensorsOptions, 'serverUrl') || !sensorsOptions.serverUrl) {
      logger.warn(
        'SENSORSDATA WARNING: sensors options serverUrl is required.'
      );
    }

    // 如果已经加载
    if (cache.get(CacheSelector.LOADED) === true) {
      metrics.loadedMoreThanOnce.inc();
      if (typeof writeKey === 'string' && !cache.get(CacheSelector.WRITE_KEY)) {
        logger.warn(
          'DEPRECATION WARNING: pass write key into each event, not the load function.'
        );
        cache.set(CacheSelector.WRITE_KEY, writeKey);
      }

      return;
    }

    cache.set(CacheSelector.LOADED, true);
    metrics.loaded.inc();
    if (typeof writeKey === 'string') {
      logger.warn(
        'DEPRECATION WARNING: pass write key into each event, not the load function.'
      );
      cache.set(CacheSelector.WRITE_KEY, writeKey);
    }
    if (
      analyticsOptions &&
      typeof analyticsOptions.enableDryRun === 'boolean'
    ) {
      cache.set(
        CacheSelector.DRY_RUN_MODE_ENABLED,
        analyticsOptions.enableDryRun
      );
    }
    if (
      analyticsOptions &&
      typeof analyticsOptions.allowsMarketing === 'boolean'
    ) {
      cache.set(
        CacheSelector.PRIVACY_ALLOW_MARKETING,
        analyticsOptions.allowsMarketing
      );
    }
    if (
      analyticsOptions &&
      typeof analyticsOptions.allowsPerformance === 'boolean'
    ) {
      cache.set(
        CacheSelector.PRIVACY_ALLOW_PERFORMANCE,
        analyticsOptions.allowsPerformance
      );
    }
    if (analyticsOptions && typeof analyticsOptions.countryCode === 'string') {
      cache.set(
        CacheSelector.COUNTRY_CODE,
        analyticsOptions.countryCode.toUpperCase()
      );
    }

    initialize(sensorsOptions);
  } catch (err) {
    const message =
      err instanceof Error && err.message ? err.message : 'unknown error';
    const stack = err instanceof Error && err.stack ? err.stack : '';
    logger.error(err);
    metrics.loadingFailed.inc(`${message}${stack}`);
  }
};

// 事件上报
const track = (
  eventName: unknown,
  properties: unknown,
  options: unknown,
  callback?: AnalyticsCallback
): void => {
  try {
    // 事件名不能为空
    if (typeof eventName !== 'string') {
      throw new Error('eventName must be a string');
    }

    // 自定义消息 ID
    const messageId = generateMessageId();
    const rawEvent: RawEvent = {
      // 随机生成的消息ID
      messageId,
      // 事件名称
      name: eventName,
      options: (typeof options === 'object' ? options : {}) as JSONObject,
      properties: (typeof properties === 'object'
        ? properties
        : {}) as JSONObject,
      timestamp: new Date().toISOString(),
      // 事件类型 => SA track
      type: 'track'
    };
    ready((): void => {
      // make event
      makeAnalyticsEvent(rawEvent).then((result): void => {
        const [analyticsEvent, drop] = result;
        if (DEV_DEBUG) {
          logger.debug('from analyticsEvent:', JSON.stringify(analyticsEvent));
        }

        const sensorsdataEvent = DataUtils.analyticsEventToSensorsEvent(
          analyticsEvent
        );
        if (DEV_DEBUG) {
          logger.debug('to analyticsEvent:', JSON.stringify(sensorsdataEvent));
        }
        const cbPlayload = {
          event: replaceString(eventName),
          properties: sensorsdataEvent,
          type: 'track'
        };
        if (isDryRunMode()) {
          handleCallback({ ...cbPlayload, __DRY_RUN__: true }, callback);

          return;
        }
        if (!isDryRunMode() && !drop) {
          send(
            'track',
            replaceString(eventName),
            sensorsdataEvent,
            (res: object): void => {
              handleCallback({ ...cbPlayload, ...res }, callback);
            }
          );
        }
        // 数据校验有问题
        if (!isDryRunMode() && drop) {
          logger.debug(`Event Dropped. Reason: ${drop.reason}`);
        }
      });
    });
  } catch (err) {
    logger.error(err);
  }
};

/**
 * 页面数据上报
 *
 * 可变参数，可以传入任意数量的属性
 * @param pageNameOrCategory
 * @param propertiesOrPageName
 * @param optionsOrProperties
 * @param callbackOrOptions
 * @param justCallback
 */
const page = (
  // page(view) name
  pageNameOrCategory: unknown,
  // properties
  propertiesOrPageName: unknown,
  // options: Ask the Data Capture team for write keys，{ $schema: 'SCHEMA_ID', writeKey: 'YOUR_WRITE_KEY' }
  optionsOrProperties: unknown,
  // 回调函数
  callbackOrOptions?: AnalyticsCallback | object,
  justCallback?: AnalyticsCallback
): void => {
  try {
    const {
      category,
      pageName,
      properties = {},
      options = {},
      callback
    } = extractPageArgs(
      // 重新处理参数, 可以考虑函数重载处理: https://ts.xcatliu.com/basics/type-of-function.html#%E9%87%8D%E8%BD%BD
      pageNameOrCategory,
      propertiesOrPageName,
      optionsOrProperties,
      callbackOrOptions,
      justCallback
    );

    if (typeof pageName !== 'string') {
      throw new Error('pageName must be a string');
    }

    // 生成随机消息 ID
    const messageId = generateMessageId();

    // 组装事件对象
    const rawEvent: RawEvent = {
      category,
      messageId,
      name: pageName,
      options: (typeof options === 'object' ? options : {}) as JSONObject,
      properties: (typeof properties === 'object'
        ? properties
        : {}) as JSONObject,
      timestamp: new Date().toISOString(),
      // 事件类型为 page => SA trace => $pageview
      type: 'page'
    };

    ready(
      // eslint-disable-next-line @typescript-eslint/promise-function-async
      (): Promise<void> =>
        // NOTE typescript wants us to use async functions
        // BUT jest does not execute the internal async function unless it's written as a promise
        // Both work but tests were not going to pass without more work or version updates for ts or jest
        // make event
        makeAnalyticsEvent(rawEvent).then((result): void => {
          const [analyticsEvent, drop] = result;

          if (DEV_DEBUG) {
            logger.debug(
              'from analyticsEvent:',
              JSON.stringify(analyticsEvent)
            );
          }

          const sensorsdataEvent = DataUtils.analyticsEventToSensorsEvent(
            analyticsEvent
          );
          if (DEV_DEBUG) {
            logger.debug(
              'to analyticsEvent:',
              JSON.stringify(sensorsdataEvent)
            );
          }

          const cbPlayload = {
            event: '$pageview',
            properties: sensorsdataEvent,
            type: 'track'
          };
          if (isDryRunMode()) {
            handleCallback({ ...cbPlayload, __DRY_RUN__: true }, callback);

            return;
          }
          if (!isDryRunMode() && !drop) {
            send(
              'track',
              '$pageview',
              sensorsdataEvent,
              (res: object): void => {
                handleCallback({ ...cbPlayload, ...res }, callback);
              }
            );
          }
          if (!isDryRunMode() && drop) {
            logger.debug(`Event Dropped. Reason: ${drop.reason}`);
          }
        })
    );
  } catch (err) {
    logger.error(err);
  }
};

/** DEPRECATED */
const timeout = (ms: unknown): void => {
  logger.warn(
    'DEPRECATION WARNING: support for timeout will be removed in the future.'
  );
  if (typeof ms === 'number') {
    cache.set(CacheSelector.TIMEOUT, ms);
  }
};

const anonymousId = (value?: unknown): string => {
  if (value && typeof value === 'string') {
    cache.set(CacheSelector.ANONYMOUS_ID, value);
    logger.debug(`Anonymous ID Set: "${value}"`);
  } else {
    logger.debug('Getting Anonymous ID');
  }

  return cache.get(CacheSelector.ANONYMOUS_ID) as string;
};

/**
 * get sensorsdata preset properties
 * @returns Promise<JSONObject>
 *
 * @update: 2022-06-14 移除获取preset properties 方法
 */
// const getPresetProperties = (): Promise<JSONObject> => {
//   return new Promise((resolve) => {
//     try {
//       send('quick', 'isReady', () => {
//         const result: JSONObject = send('getPresetProperties');
//         resolve(result);
//       })
//     } catch (error) {
//       logger.error(error);
//     }
//   })
// }

export { anonymousId, debug, load, page, track, timeout };
