/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Cache, CacheSelector, cache as _cache } from '../cache';
import {
  DIRECT_LOAD_MATCH_LIST,
  LocalStorageSelector,
  NIKE_INTERNAL_SITE_LIST
} from './constant';
import { find, isNull, isString, isUndefined } from 'lodash';
import { read, write } from '../lib/localStorage';
import { SensorsDataAnalytic } from './sensorsdata.types';

/**
 * current website: hostname like 'nike.com.cn'
 * @param referrer {string} document.referrer
 * @returns boolean
 */
const isCurrentSite = (referrer: string): boolean => {
  let matchStr = '';
  try {
    const url = new URL(referrer);
    matchStr = url.hostname;
  } catch (error) {
    matchStr = referrer;
  }

  // match referrer, if match website, return false
  const matchResult = find(
    NIKE_INTERNAL_SITE_LIST,
    (currentSite: string): boolean => matchStr.indexOf(currentSite) > -1
  );

  return !isUndefined(matchResult);
};

/**
 * get cpCode
 * @returns cpCode string | null
 */
const getCpCode = (cache: Cache): string | null => {
  let cpCode: string | null = null;
  const search = cache.get(CacheSelector.GLOBAL_SEARCH) as string;
  // 解析出 cp={XXX}
  const matcher = /cp=([^&#]*)/;
  if (search) {
    const cpCodes = search.match(matcher);
    if (!isNull(cpCodes)) {
      cpCode = decodeURI(cpCodes[1]);
    }
  }

  return cpCode;
};

/**
 * get latestReferrer
 * @returns latestReferrer string | null
 */
const getLatestReferrer = (url: string): string | null => {
  let latestReferrer: string | null = null;
  if (isString(url)) {
    const isUrl = url.match(/^http/);
    if (isUrl) {
      latestReferrer = url;
    }
  }

  return latestReferrer;
};

/**
 * get localStorage cpCode latestReferrer
 * @returns {cpCode:string, latestReferrer:string}
 */
const getLocalCpData = (): {
  cpCode: string | null;
  latestReferrer: string | null;
} => {
  const cpCode = read(LocalStorageSelector.CP_CODE);
  const latestReferrer = read(LocalStorageSelector.LATEST_REFERRER);

  return {
    cpCode: isString(cpCode) ? cpCode : null,
    latestReferrer: isString(latestReferrer) ? latestReferrer : null
  };
};

/**
 * @param cpCode
 * @param latestReferrer
 * @returns value {string | undefined}
 */
const getMarketingChannel = (
  cpCode: string | null,
  latestReferrer: string | null,
  hostname: string
): string | undefined => {
  let value: string | undefined;

  // match hostname
  const hasUrlMatch = find(
    DIRECT_LOAD_MATCH_LIST,
    (str: string): boolean => hostname.indexOf(str) > -1
  );

  if (!isUndefined(hasUrlMatch) && isNull(cpCode) && isNull(latestReferrer)) {
    value = 'Direct Load';
  }

  return value;
};

/**
 * According to $event_session_id to set cp_code latest_referrer marketing_channel
 * @param sensors sensors sdk instance
 */
const initPropertiesMiddleware = (sensors: SensorsDataAnalytic): void => {
  // register properties plugin
  const RegisterProperties = sensors.use('RegisterProperties', {}) as any;
  RegisterProperties.hookRegister(
    (data: any): Record<string, unknown> => {
      try {
        const {
          cpCode: originCpCode,
          $event_session_id: newSessionId,
          $latest_referrer
        } = data?.data?.properties;
        // get old session from localStorage
        const oldSessionId = read(LocalStorageSelector.SESSION_ID);

        // replace sessionId
        if (newSessionId !== oldSessionId) {
          write(LocalStorageSelector.SESSION_ID, newSessionId);
          if (isCurrentSite(document.referrer)) {
            write(LocalStorageSelector.CP_CODE, null);
            write(LocalStorageSelector.LATEST_REFERRER, null);
          }
        }

        const urlCpCode = getCpCode(_cache);
        // According to isCurrentSite whether to init cpData
        if (isCurrentSite(document.referrer)) {
          // if is current nike website and url include cpcode, should update.
          if (originCpCode || urlCpCode) {
            write(LocalStorageSelector.CP_CODE, originCpCode || urlCpCode);
          }
        } else {
          const latestReferrer = getLatestReferrer($latest_referrer);
          write(LocalStorageSelector.CP_CODE, urlCpCode);
          write(LocalStorageSelector.LATEST_REFERRER, latestReferrer);
        }

        // get latestReferrer cpData from localSttorage, update properties
        const { cpCode, latestReferrer } = getLocalCpData();
        const marketingChannel = getMarketingChannel(
          cpCode,
          latestReferrer,
          location.hostname
        );

        // replace some properties
        return {
          $latest_referrer: latestReferrer,
          cp_code: cpCode,
          marketing_channel: marketingChannel
        };
      } catch (error) {
        return {};
      }
    }
  );
};

export {
  isCurrentSite,
  getCpCode,
  getLatestReferrer,
  getLocalCpData,
  getMarketingChannel,
  initPropertiesMiddleware
};
