// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any
declare var window: any;

export default function addPageAction(
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventProperties: Record<string, any>
): void {
  if (window && window.newrelic) {
    window.newrelic.addPageAction(eventName, eventProperties);
  }
}
