// 暂时不明
import { sendInsights } from './insights';
type Counter = {
  inc(msg?: string): void;
};

// 发送事件
const newCounter = (name: string): Counter => {
  const inc = (msg?: string): void => {
    // 事件名 + 事件内容
    sendInsights(name, msg);
  };

  return { inc };
};

// 每次执行后，也会记录一次，仅在 load 时有使用
// 将数据提交到 newrelic, （感觉不是很重要）
// https://newrelic.com/blog/best-practices/new-relic-insights-getting-started-best-practices
const metrics = {
  anonymousIdValueImproperlySet: newCounter(
    'anonymous_id_value_improperly_set'
  ),
  eventProcessingFailed: newCounter('event_processing_failed'),
  loaded: newCounter('loaded'),
  loadedMoreThanOnce: newCounter('loaded_more_than_once'),
  loadingFailed: newCounter('loading_failed'),
  pageEventDropped: newCounter('page_event_dropped'),
  pageEventSent: newCounter('page_event_sent'),
  trackEventDropped: newCounter('track_event_dropped'),
  trackEventSent: newCounter('track_event_sent')
};

export { metrics };
