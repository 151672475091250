import { AnalyticsEvent, Properties, RawEvent } from './analytics-event';
import { Cookies } from '../lib/cookie';
import { cache } from '../cache';
import { getBaseProperties } from './base';
import { getContext } from './context';
import { getProperties } from './properties';
import { merge } from '../lib/merge';
import { prune } from '../lib/prune';

type DropReason = string;
type Drop = {
  reason: DropReason;
};

const cookies = new Cookies();

const eventShouldBeDropped = (
  analyticsEvent: AnalyticsEvent
): DropReason | undefined => {
  if (!analyticsEvent) {
    return 'analytics-event-missing';
  }

  if (
    !analyticsEvent.properties.consumer.allowsMarketing ||
    !analyticsEvent.properties.consumer.allowsPerformance
  ) {
    return 'user-permission-denied';
  }

  return undefined;
};

/**
 *
 * @param rawEvent
 * @returns
 * @test test('page', async (): Promise<void> => {}
 */
const makeAnalyticsEvent = async (
  rawEvent: RawEvent
): Promise<[AnalyticsEvent, Drop | undefined]> => {
  const {
    category,
    messageId,
    options,
    properties,
    timestamp,
    type
  } = rawEvent;

  const analyticsEvent = {
    // 获取基本参数
    ...getBaseProperties(rawEvent),
    // 获取上下文
    context: getContext(rawEvent, cache),
    // 上报隐私级别/会员信息/登录状态等等
    properties: await getProperties(rawEvent, cache, cookies),
    // 时间戳
    sentAt: new Date().toISOString()
  };

  const rawAnalyticsEvent = {
    category,

    messageId,

    /**
     * fix by tangliangcheng, 2020-06-30, 不解构 options, 直接 TO STRING 上报
     */
    // ...options,
    options,
    properties: properties as Properties,
    timestamp,
    // page | track
    type
  };

  // 合并上报信息
  const mergedAnalyticsEvent = merge<AnalyticsEvent>(
    analyticsEvent,
    rawAnalyticsEvent
  );

  // 删除不必要的属性
  const prunedAnalyticsEvent = <AnalyticsEvent>prune(mergedAnalyticsEvent);

  // 校验上报的数据
  /**
   * 1. 校验是否有值
   * 2. 检查隐私级别，是否允许上报
   */
  const dropReason = eventShouldBeDropped(prunedAnalyticsEvent);

  return [
    prunedAnalyticsEvent,
    dropReason ? { reason: dropReason } : undefined
  ];
};

export { makeAnalyticsEvent };
