import { addToQueue, drainQueue } from './queue';
import { AnalyticsSensorsOptions } from '../api/common';
import { cache } from '../cache';
import { initPrivacy } from './init-privacy';
import { initSensors } from '../sensorsdata';
import { initUserProfile } from './init-user-profile';

let isReady = false;

const initialize = async (
  sensorsOptions: AnalyticsSensorsOptions
): Promise<void> => {
  // do sensors sdk init, must be before initUnit
  await initSensors(cache, sensorsOptions);

  // do privacy first
  await initPrivacy(cache);

  // do user profile init, form localStorage
  await initUserProfile();

  // after asynchronous operations complete
  isReady = true;
  drainQueue();
};

const ready = (cb: () => void): void => {
  if (isReady) {
    cb();

    return;
  }

  addToQueue(cb);
};

const reset = (): void => {
  isReady = false;
};

export { initialize, ready, reset };
