import { Cache, CacheSelector } from '../cache';
import { CP } from './analytics-event.d';

const getCP = (cache: Cache): CP => {
  const cp: CP = {};
  // 从路由获取查询参数
  // 查询，搜索参数
  const search = cache.get(CacheSelector.GLOBAL_SEARCH) as string | undefined;
  // 获取访问地址的 referrer 属性
  const referrer = cache.get(CacheSelector.GLOBAL_REFERRER) as
    | string
    | undefined;
  // 解析出 cp={XXX}
  const matcher = /cp=([^&#]*)/;
  if (search !== undefined) {
    const cpCodes = search.match(matcher);
    if (cpCodes !== null) {
      [, cp.cpCode] = cpCodes;
    }
  }
  if (referrer !== undefined) {
    const cpCodeReferrers = referrer.match(matcher);
    if (cpCodeReferrers !== null) {
      [, cp.cpCodeReferrer] = cpCodeReferrers;
    }
  }

  return cp;
};

export { getCP };
