export const DIRECT_LOAD_MATCH_LIST = [
  'nike.cn',
  'nike.com',
  'nike.com.cn',
  'nikestore',
  'nikeman',
  'nikemen',
  'nikewoman',
  'nikewomen',
  'nikekid',
  'nikekids',
  'nikebasketball',
  'nikesoccer',
  'nikerunning',
  'nikensw',
  'pay'
];

export const NIKE_INTERNAL_SITE_LIST = ['nike.com.cn'];

enum LocalStorageSelector {
  CP_CODE = 'sensors_cp_code',
  LATEST_REFERRER = 'sensors_latest_referrer',
  SESSION_ID = 'sensors_session_id'
}

export { LocalStorageSelector };
