import { Cache, CacheSelector } from '../cache';
import { Properties, RawEvent } from './analytics-event.d';
import { Cookies } from '../lib/cookie';
import { getCP } from './cp';
import { getConsumer } from './consumer';
import { getContent } from './content';
import { getPreviousView } from './previous-view';

const getProperties = async (
  rawEvent: RawEvent,
  cache: Cache,
  cookies: Cookies
): Promise<Properties> => {
  const { name, type: eventType } = rawEvent;

  // 语言，从全局获取
  const language = cache.get(CacheSelector.GLOBAL_BROWSER_LANGUAGE) as string;
  const classification = 'experience event';
  const consumer = await getConsumer(rawEvent, cache);
  const content = getContent(rawEvent);
  const { cpCode, cpCodeReferrer } = getCP(cache);
  const eventName = eventType === 'page' ? 'Page Viewed' : name;
  const previousView = getPreviousView(rawEvent, cache, cookies);

  const wrapper = {
    build: PRODUCTION ? 'production' : 'development',
    version: VERSION
  };

  return {
    classification,
    consumer,
    content,
    cpCode,
    cpCodeReferrer,
    eventName,
    eventType,
    language,
    previousView,
    wrapper
  };
};

export { getProperties };
