import { Cache, CacheSelector } from '../cache';
import { has, snakeCase } from 'lodash';
import { AnalyticsSensorsOptions } from '../api/common';
import { CacheSensorsSelector } from './cache-sensors-selector';
import { SensorsDataAnalytic } from './sensorsdata.types';
import { initPropertiesMiddleware } from './properties-middleware';
import { logger } from '../logger';

/**
 * 检查是否有全局 sensors data analytic 对象
 * @returns {boolean}
 */
const hasGlobalSensorsDataAnalytic = (): boolean => {
  try {
    if (window && has(window, 'sensorsDataAnalytic201505')) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

/**
 * 检查是否有全局 sensors web plugin: SessionEvent
 * @returns {boolean}
 */
const hasSessionEventPlugin = (): boolean => {
  try {
    if (hasGlobalSensorsDataAnalytic()) {
      // @ts-ignore
      if (window.SensorsDataWebJSSDKPlugin.SessionEvent) {
        return true;
      }
    }

    return false;
  } catch (error) {
    return false;
  }
};

/**
 * 检查是否有全局 sensors 实例
 * @returns {boolean}
 */
//  const hasGlobalSensorsInstance = (): boolean => {
//   try {
//     if (window && has(window, CacheSensorsSelector.SENSORS_INSTANCE)) {
//       return true;
//     }
//     return false;
//   } catch (error) {
//     return false;
//   }
// };

/**
 * 保存 sensors 实例到全局
 * @param sensors
 */
//  const cacheToGlobal = (sensors: any): void => {
//   if (window) {
//     // @ts-ignore
//     window[CacheSensorsSelector.SENSORS_INSTANCE] = sensors;
//   }
// };

/**
 * 将传入的对象转换为 snake_case
 * @param options
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertToSnakeCase = (options: any): JSONObject => {
  const result: JSONObject = {};
  Object.keys(options).forEach((key: string): void => {
    result[snakeCase(key)] = options[key];
  });

  return result;
};

/**
 *
 * @param cache
 * @param sensorsOptions SDK初始化参数 see: https://manual.sensorsdata.cn/sa/latest/sdk-api-web-42795073.html#id-.SDKAPI(Web)v2.3-SDK%E5%88%9D%E5%A7%8B%E5%8C%96%E5%8F%82%E6%95%B0
 * @returns
 */
const initSensors = (
  cache: Cache,
  sensorsOptions: AnalyticsSensorsOptions
): void => {
  try {
    // @ts-ignore
    window[CacheSensorsSelector.SENSORS_INSTANCE] = undefined;

    if (!sensorsOptions) {
      logger.error(
        'please check the sensors sdk init options, see https://manual.sensorsdata.cn/sa/latest/sdk-api-web-42795073.html'
      );

      return;
    }
    if (hasGlobalSensorsDataAnalytic()) {
      if (sensorsOptions.sdkUrl) {
        logger.warn(
          'sensordata sdk url is an invalid parameter, which has been introduced by default and does not need to be reloaded'
        );
      }
      const sensors: SensorsDataAnalytic = (window.sensorsDataAnalytic201505 as unknown) as SensorsDataAnalytic;
      const options: JSONObject = convertToSnakeCase({
        ...sensorsOptions,
        sdkUrl: undefined
      });
      sensors.init(options);
      // 检查是否有 SessionEvent 插件，并初始化
      if (hasSessionEventPlugin()) {
        sensors.use('SessionEvent', {});
        // 增加上报 properties 拦截：流量归因

        // 2023.01.17 update by Phil.Tang, web attribution will be merge to develop
        initPropertiesMiddleware(sensors);
      } else {
        logger.error('session-event plugin initialization failed');
      }
      // update anonymous id to sensorsdata
      const anonymousId = cache.get(CacheSelector.ANONYMOUS_ID) || '';

      sensors.identify(anonymousId.toString(), true);

      // cache to global
      // cacheToGlobal(sensors);
    } else {
      logger.error(
        'please load the sensors sdk first, see https://manual.sensorsdata.cn/sa/latest/web-js-sdk-7548149.html'
      );
    }

    return;
  } catch (error) {
    logger.error(error);
  }
};

/**
 * send data to sensorsdata
 * @param method sensorsdata sdk api name
 * @param args 参数
 */
// eslint-disable-next-line no-warning-comments
// TODO: the typescript of this function needs to be fixed.
const send = <K extends keyof SensorsDataAnalytic>(
  method: K,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
): // eslint-disable-next-line consistent-return
void => {
  try {
    if (hasGlobalSensorsDataAnalytic()) {
      // @ts-ignore
      // const sensors: SensorsDataAnalytic = window[CacheSensorsSelector.SENSORS_INSTANCE];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const sensors = window.sensorsDataAnalytic201505 as any;

      return sensors[method].apply(null, args);
    }
  } catch (err) {
    logger.error(
      'please check the sensors sdk init status, see https://manual.sensorsdata.cn/sa/latest/web-js-sdk-7548149.html'
    );
  }
};

export { send, initSensors, convertToSnakeCase, hasGlobalSensorsDataAnalytic };
