import { Cache, CacheSelector } from '../../cache';
import { Cookies } from '../../lib/cookie';
import { isCookieRequiredCountry } from './eu-countries';

type CountryCode = string;
type RegionCode = string;
type Timezone = string;
type Latitude = number;
type Longitude = number;

interface Locale {
  getCountryCode(): CountryCode | undefined;
}

type Geoloc = {
  cc?: CountryCode;
  rc?: RegionCode;
  tz?: Timezone;
  la?: Latitude;
  lo?: Longitude;
};

const parseCookie = (cookieValue: string): Geoloc => {
  const geoloc: Geoloc = cookieValue.split(',').reduce(
    (acc, curr: string): Geoloc => {
      const [key, value] = curr.split('=');

      switch (key) {
        case 'cc':
        case 'rc':
        case 'tz':
          acc[key] = value;
          break;
        case 'la':
        case 'lo':
          acc[key] = Number(value);
          break;
        default:
      }

      return acc;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {} as any
  );

  return geoloc;
};

const getCountryCodeFromUrl = (cache: Cache): string | undefined => {
  const pathName = cache.get(CacheSelector.GLOBAL_PATH);
  const countryCodePattern = /(\/[a-z]{2}\/)/;
  const countryCodeFromUrl =
    typeof pathName === 'string' && pathName.match(countryCodePattern);

  if (countryCodeFromUrl && Array.isArray(countryCodeFromUrl)) {
    return countryCodeFromUrl[0].replace(/[^\w]/g, '');
  }

  return undefined;
};

const getCountryCodeFromGeoloc = (cookies: Cookies): string | undefined => {
  const geolocCookie = cookies.get('geoloc');

  if (geolocCookie) {
    return parseCookie(geolocCookie).cc;
  }

  return undefined;
};

const initializeLocale = (cache: Cache, cookies: Cookies): Locale => {
  const getCountryCode = (): string | undefined => {
    // 从 URL 中获取
    const countryCodeFromUrl = getCountryCodeFromUrl(cache);
    // 从 cookie 获取
    const countryCodeFromGeoloc = getCountryCodeFromGeoloc(cookies);

    if (isCookieRequiredCountry(countryCodeFromGeoloc)) {
      return countryCodeFromGeoloc;
    }

    if (isCookieRequiredCountry(countryCodeFromUrl)) {
      return countryCodeFromUrl;
    }

    return countryCodeFromGeoloc || countryCodeFromUrl;
  };

  return {
    getCountryCode
  };
};

export { initializeLocale, Locale };
