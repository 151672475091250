const getDomain = (): string => {
  const { hostname } = location;
  const maxLength = 2;

  const hostParts = hostname.split('.');

  if (hostParts.length === maxLength) {
    return `.${hostname}`;
  }

  if (hostParts.length > maxLength) {
    return `.${hostParts
      .slice(0)
      /* eslint-disable-next-line no-magic-numbers */
      .slice(-(hostParts.length === 4 ? 3 : 2))
      .join('.')}`;
  }

  return hostname;
};

export { getDomain };
