import { Cookies } from '../../lib/cookie';
import { isCookieRequiredCountry } from './eu-countries';

type PrivacyPreferences = {
  getPreferences: () => Preferences;
  ready: () => Promise<void>;
};

type Preferences = {
  allowMarketing: boolean;
  allowPerformance: boolean;
};

// eslint-disable-next-line no-magic-numbers
type PrivacyLevel = 0 | 1 | 2 | 3;

const PRIVACY_SETTINGS: { [key: number]: Preferences } = {
  0: { allowMarketing: false, allowPerformance: false },
  1: { allowMarketing: false, allowPerformance: true },
  2: { allowMarketing: true, allowPerformance: false },
  3: { allowMarketing: true, allowPerformance: true }
};

const initializePrivacyPreferences = (
  cookies: Cookies,
  countryCode: string | undefined
): PrivacyPreferences => {
  const getPreferences = (): Preferences => {
    // 获取隐私级别
    const getPrivacyLevel = (level?: string): PrivacyLevel => {
      switch (level) {
        case '':
        case undefined:
          // eslint-disable-next-line
          return 3;
        case '1':
          return 1;
        case '2':
          // eslint-disable-next-line no-magic-numbers
          return 2;
        case '3':
          // eslint-disable-next-line no-magic-numbers
          return 3;
        case '0':
        default:
          return 0;
      }
    };

    const privacyLevel = getPrivacyLevel(cookies.get('sq'));

    return PRIVACY_SETTINGS[privacyLevel];
  };

  // eslint-disable-next-line @typescript-eslint/promise-function-async
  const ready = (): Promise<void> =>
    new Promise((resolve): void => {
      const privacyReadyInterval = setInterval((): void => {
        // 检查是否要隐私设置，根据国家不同，主要是 EU
        if (!isCookieRequiredCountry(countryCode)) {
          clearInterval(privacyReadyInterval);

          resolve();

          return;
        }

        const cookieValue = cookies.get('sq');

        if (cookieValue) {
          clearInterval(privacyReadyInterval);

          resolve();
        }
        // eslint-disable-next-line no-magic-numbers
      }, 100);
    });

  return {
    getPreferences,
    ready
  };
};

export { initializePrivacyPreferences };
