/**
 * https://github.com/marcuswestin/store.js/blob/master/storages/localStorage.js
 */

var Global = typeof window !== 'undefined' ? window : global;

module.exports = {
  name: 'localStorage',
  read: read,
  write: write,
  each: each,
  remove: remove,
  clearAll: clearAll
};

function localStorage() {
  return Global.localStorage;
}

function read(key) {
  const data = localStorage().getItem(key);
  return _deserialize(data);
}

function write(key, data) {
  return localStorage().setItem(key, data);
}

function each(fn) {
  for (var i = localStorage().length - 1; i >= 0; i--) {
    var key = localStorage().key(i);
    fn(read(key), key);
  }
}

function remove(key) {
  return localStorage().removeItem(key);
}

function clearAll() {
  return localStorage().clear();
}

/**
 * deserialize item value
 * see: https://github.com/marcuswestin/store.js/blob/b8e22fea8738fc19da4d9e7dbf1cda6e5185c481/src/store-engine.js#L148
 * @param {*} strVal
 * @returns string | number | object | null | undefined
 */
function _deserialize(strVal) {
  // It is possible that a raw string value has been previously stored
  // in a storage without using store.js, meaning it will be a raw
  // string value instead of a JSON serialized string. By defaulting
  // to the raw string value in case of a JSON parse error, we allow
  // for past stored values to be forwards-compatible with store.js
  let val = '';
  try {
    val = JSON.parse(strVal);
  } catch (e) {
    val = strVal;
  }

  return val;
}
