// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any
declare var window: any;

interface UserProfile {
  upmId: string | undefined;
  signInFlow: string | undefined;
  userType: string;
}

interface Identity {
  getUserProfile: () => Promise<UserProfile | undefined>;
  getIsSwooshUser: () => Promise<boolean>;
  getInitialized: () => Promise<boolean>;
}

export default function getWebShellClientIdentity(): Identity | undefined {
  if (window && window.webShellClient && window.webShellClient.identity) {
    return window.webShellClient.identity;
  }

  return undefined;
}
