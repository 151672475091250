import { hasLocalStorage } from './utils';

type DebugMode = 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'OFF';

const key = 'debug';
const value = (namespace: string): string => `analytics:${namespace}`;

const getDebugModeFromQueryString = (): string => {
  const queryString = window.location.search;
  if (
    queryString.indexOf(`${key}-mode`) > -1 &&
    typeof URLSearchParams !== 'undefined'
  ) {
    const qs = new URLSearchParams(queryString);

    return qs.get(`${key}-mode`) || '';
  }

  return '';
};

/**
 * 设置 debug mode 到全局
 * @param namespace log 命名空间
 * @param mode 模式
 * @returns
 */
const setDebugMode = (
  namespace: string | false = '*',
  mode: string = '*'
): void => {
  if (!hasLocalStorage()) {
    return;
  }
  // if there's a namespace available, debug mode is enabled
  // if namespace is falsy, debug mode is disabled
  window.localStorage.setItem(key, namespace ? value(namespace) : '');
  window.localStorage.setItem(`${key}-mode`, namespace && mode ? mode : '');
};

const getDebugMode = (): DebugMode => {
  const defaultMode = 'ERROR' as DebugMode;
  if (!hasLocalStorage()) {
    return defaultMode;
  }
  const newMode = getDebugModeFromQueryString().toUpperCase() as DebugMode;
  if (newMode) {
    setDebugMode(undefined, newMode);
  }
  let storedMode = window.localStorage.getItem(`${key}-mode`);

  if (storedMode === '*') {
    storedMode = 'DEBUG';
  }

  return (storedMode || defaultMode) as DebugMode;
};

export { getDebugMode, setDebugMode };
