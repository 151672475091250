import { Cache, CacheSelector } from '../cache';
import { Consumer, LoginStatus, RawEvent } from './analytics-event.d';
import addPageAction from '../initialize/add-page-action';
import { getUpmId } from './upm-id';
import getWebShellClientIdentity from '../get-web-shell-client-identity';

const getConsumer = async (
  _rawEvent: RawEvent,
  cache: Cache
): Promise<Consumer> => {
  const adobeVisitorId = cache.get(CacheSelector.ADOBE_ANALYTICS_ID);
  const adobeMarketingCloudId = cache.get(
    CacheSelector.ADOBE_MARKETING_CLOUD_ID
  );
  const allowsMarketing = cache.get(CacheSelector.PRIVACY_ALLOW_MARKETING);
  const allowsPerformance = cache.get(CacheSelector.PRIVACY_ALLOW_PERFORMANCE);
  const anonymousId = cache.get(CacheSelector.ANONYMOUS_ID);
  let upmId = getUpmId();
  // change to localStorage, user profile user type is undefined
  // so, init userType = undefined
  let userType = cache.get(CacheSelector.USER_TYPE);

  // userType = undefined, so init isSwoosh = false
  let isSwoosh = userType === 'EMPLOYEE';
  let signInFlow;
  const identity = getWebShellClientIdentity();
  if (identity && (await identity.getInitialized())) {
    isSwoosh = await identity.getIsSwooshUser();
    const userProfile = await identity.getUserProfile();
    if (userProfile) {
      /* eslint-disable prefer-destructuring */
      upmId = userProfile.upmId;
      userType = userProfile.userType;
      signInFlow = userProfile.signInFlow;
      /* eslint-enable prefer-destructuring */
    } else {
      // WebShellClient user profile is undefined, user not logged in
      // so some value is undefined
      upmId = undefined;
      userType = undefined;
      signInFlow = undefined;
    }
  } else {
    addPageAction('DCAC_MISSING_WSC_IDENTITY', { anonymousId });
  }

  let loginStatus = ((type?: unknown): LoginStatus => {
    switch (type) {
      case 'EMPLOYEE':
        return 'logged in:swoosh';
      case 'DEFAULT_USER':
        return 'logged in';
      case 'MEMBER':
        return 'logged in';
      default:
        // 未登录
        return 'not logged in';
    }
  })(userType);
  // if upmId is truthy, they would be logged in, despite what userType says
  if (!isSwoosh && upmId) {
    loginStatus = 'logged in';
  }
  // account for checking for swoosh NOT purely based on userType since accounts will convey this differently in future
  if (identity && isSwoosh) {
    loginStatus = 'logged in:swoosh';
  }

  if (typeof anonymousId !== 'string') {
    throw new Error('anonymousId must be a string');
  }

  return {
    adobeMarketingCloudId:
      typeof adobeMarketingCloudId === 'string'
        ? adobeMarketingCloudId
        : undefined,
    adobeVisitorId:
      typeof adobeVisitorId === 'string' ? adobeVisitorId : undefined,
    allowsMarketing:
      typeof allowsMarketing === 'boolean' ? allowsMarketing : false,
    allowsPerformance:
      typeof allowsPerformance === 'boolean' ? allowsPerformance : false,
    anonymousId,
    isSwoosh,
    loginStatus,
    signInFlow: typeof signInFlow === 'string' ? signInFlow : undefined,
    upmId: typeof upmId === 'string' ? upmId : 'guest'
  };
};

export { getConsumer };
