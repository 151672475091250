const hasLocalStorage = (): boolean => {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);

    return true;
  } catch (err) {
    return false;
  }
};

export { hasLocalStorage };
