/**
 * 生成随机长度的全小写字符或大写字符
 * @param length 生成字符长度
 * @param toUpper 是否大写
 * @returns string
 */
const randomWords = (length: number, toUpper: boolean = false) => {
  let result = '';
  for (let i = 0; i < length; i++) {
    const word = String.fromCharCode(97 + Math.floor(Math.random() * 26)); // a-z
    result += toUpper ? word.toUpperCase() : word;
  }
  return result;
};

/**
 * 字符串替换 [space, >]
 * @param str origin string
 * @param separator 分割符
 * @returns target string
 */
const replaceString = (str: string, separator: string = '_') => {
  // @ts-ignore
  const reg = new RegExp(/\s|<|>|\.|-/, 'g');
  return str.replace(reg, separator);
};

export { randomWords, replaceString };
