import { isObject, isString, isUndefined } from 'lodash';
import { PresetProperties } from '../../src/sensorsdata/sensorsdata.types';
// import { LoggerLevel } from "../logger";

export type AnalyticsCallback = (payload?: object) => void;

export type DataCaptureAnalyticsClient = {
  debug(namespace?: string | false): void;
  load(
    writeKeyOrAnalyticsOptions?: unknown,
    analyticsOptionsOrSensorsOptions?: unknown,
    sensorsOptionsOrUndefined?: unknown
  ): void;
  track(
    eventName: unknown,
    properties: unknown,
    options: unknown,
    callback?: AnalyticsCallback
  ): void;
  page(
    pageNameOrCategory: unknown,
    propertiesOrPageName: unknown,
    optionsOrProperties: unknown,
    callbackOrOptions?: object | AnalyticsCallback,
    justCallback?: AnalyticsCallback
  ): void;
  timeout(ms: unknown): void;
};

export interface AnalyticsOptions {
  enableDryRun?: boolean;
  allowsMarketing?: boolean;
  allowsPerformance?: boolean;
  countryCode?: string;
  uniteTimeout?: number;
  // logLevel?: LoggerLevel;
}

export interface AnalyticsSensorsOptions {
  serverUrl: string;
  sdkUrl?: string;
  name?: string;
  heatmapUrl?: string;
  webUrl?: string;
  crossSubdomain?: boolean;
  showLog?: boolean;
  useClientTime?: boolean;
  sourceChannel?: string;
  sourceType?: string;
  maxStringLength?: number;
  sendType?: 'beancon' | 'ajax';
  callbackTimeout?: number;
  queueTitme?: number;
  datasendTimeout?: number;
  presetProperties?: PresetProperties;
  isTrackSinglePage?: boolean;
}

export interface PageEventArguments {
  category: string | null;
  pageName: unknown;
  properties: unknown;
  options: unknown;
  callback?: AnalyticsCallback;
}

export interface LoadArguments {
  writeKey: string | undefined;
  sensorsOptions: AnalyticsSensorsOptions;
  analyticsOptions?: AnalyticsOptions;
}

/**
 * Helper used to normalize the variadic parameter list for the .page() API method.
 */
export const extractPageArgs = (
  pageNameOrCategory: unknown,
  propertiesOrPageName?: unknown,
  optionsOrProperties?: unknown,
  callbackOrOptions?: object | AnalyticsCallback,
  justCallback?: AnalyticsCallback
): PageEventArguments => {
  const category =
    typeof pageNameOrCategory === 'string' &&
    typeof propertiesOrPageName === 'string'
      ? pageNameOrCategory
      : null;
  // 如果没传，则默认等于 category
  const pageName =
    typeof propertiesOrPageName === 'string'
      ? propertiesOrPageName
      : pageNameOrCategory;
  const properties =
    typeof propertiesOrPageName === 'string'
      ? optionsOrProperties
      : propertiesOrPageName;
  const options =
    typeof callbackOrOptions === 'object'
      ? callbackOrOptions
      : typeof category === 'string' &&
        typeof pageName === 'string' &&
        typeof properties === 'object'
      ? null
      : optionsOrProperties;
  const callback =
    typeof callbackOrOptions === 'object' ? justCallback : callbackOrOptions;

  return {
    callback,
    category,
    options,
    pageName,
    properties
  };
};

/**
 * Helper used to normalize the variadic parameter list for the .load() API method.
 * @param writeKeyOrAnalyticsOptions
 * @param analyticsOptionsOrSensorsOptions
 * @param justSensorsOptions
 * @returns
 */
export const extractLoadArgs = (
  writeKeyOrAnalyticsOptions?: unknown,
  analyticsOptionsOrSensorsOptions?: unknown,
  justSensorsOptions?: AnalyticsSensorsOptions
): LoadArguments => {
  const writeKey = isString(writeKeyOrAnalyticsOptions)
    ? writeKeyOrAnalyticsOptions
    : undefined;

  let analyticsOptions: AnalyticsOptions = {};
  if (isString(writeKey)) {
    analyticsOptions =
      (analyticsOptionsOrSensorsOptions as AnalyticsOptions) || {};
  } else if (isUndefined(writeKey) || isObject(writeKeyOrAnalyticsOptions)) {
    analyticsOptions = (writeKeyOrAnalyticsOptions as AnalyticsOptions) || {};
  }

  // default sensorsdata web sdk options
  const defaultSensorsOptions: AnalyticsSensorsOptions = {
    maxStringLength: 10000,
    sendType: 'ajax',
    serverUrl: SENSORS_SERVER_URL
  };
  let sensorsOptions: AnalyticsSensorsOptions = defaultSensorsOptions;
  if (
    isString(writeKeyOrAnalyticsOptions) &&
    isObject(analyticsOptionsOrSensorsOptions)
  ) {
    if (!isUndefined(justSensorsOptions)) {
      sensorsOptions = { ...sensorsOptions, ...justSensorsOptions };
    }
  } else if (
    isObject(writeKeyOrAnalyticsOptions) &&
    isObject(analyticsOptionsOrSensorsOptions)
  ) {
    sensorsOptions = {
      ...sensorsOptions,
      ...analyticsOptionsOrSensorsOptions
    };
  }

  return {
    analyticsOptions,
    sensorsOptions,
    writeKey
  };
};
